<template>
    <div style="display: flex;flex-direction: column;height: 100%">
        <Header url="/index"></Header>
        <div class="login-container">
            <div class="container-box" v-loading="loading">
                <!--                <h1 style="letter-spacing: 1rem">民生泷通平台</h1>-->
                <el-form :model="user" :rules="rules" ref="ruleForm" label-width="100px" class="register-form" size="medium">
<!--                    <el-form-item label="商户类型" prop="type">-->
<!--                        <el-radio-group v-model="user.type">-->
<!--                            <el-radio label="0">个人</el-radio>-->
<!--                            <el-radio label="1">商户</el-radio>-->
<!--                        </el-radio-group>-->
<!--                    </el-form-item>-->
                    <el-form-item label="用户名" prop="userAccount">
                        <el-input v-model="user.userAccount" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="用户密码" prop="userPassword">
                        <el-input type="password" v-model="user.userPassword" placeholder="请输入密码" auto-complete="off"
                                  show-password></el-input>
                    </el-form-item>
                    <el-form-item label="重复密码" prop="confirmPassword">
                        <el-input type="password" v-model="user.confirmPassword" placeholder="请重复输入密码"
                                  auto-complete="off" show-password></el-input>
                    </el-form-item>
<!--                    <el-form-item label="商户名称" prop="shopName">-->
<!--                        <el-input v-model="user.shopName" placeholder="请输入商户名称"></el-input>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="联系人姓名" prop="realName">-->
<!--                        <el-input v-model="user.realName" placeholder="请输入商户名称"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item label="联系人手机" prop="userPhone">
                        <el-input v-model="user.userPhone" placeholder="请输入手机号" maxlength="11" minlength="11"
                                  auto-complete="off"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="短信验证码" prop="verificationCode">-->
<!--                        <el-input v-model="user.verificationCode" placeholder="验证码" maxlength="11" minlength="11"-->
<!--                                  auto-complete="off" style="width: 180px"></el-input>-->
<!--                        <el-button type="primary" plain style="margin-left: 5px" @click="sendMessage" :disabled="!timingHanlder.flag"-->
<!--                                   v-text="timingHanlder.text"></el-button>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="详细地址" prop="address">-->
<!--                        <el-input v-model="user.address" placeholder="请输入详细地址"-->
<!--                                  auto-complete="off"></el-input>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item>-->
<!--                        <el-checkbox label="同意平台网络协议"></el-checkbox>-->
<!--                    </el-form-item>-->
                </el-form>
                <div style="text-align: center">
                    <el-button type="primary" @click="regist">提交注册</el-button>
                    <el-button type="" @click="resetForm('ruleForm')">重置</el-button>
                </div>
            </div>
            <footer>
                <p class="f-p">版权所有 2008-2022 ICP备88888888 Powered by Meinfo 5.3.11 ©2008-2022</p>
            </footer>
        </div>
        <!-- <buttom></buttom> -->
    </div>
</template>

<script>
    import Header from "@/components/common/Header";
    import Buttom from "@/components/common/Buttom";

    export default {
        name: "Regist",
        data: function () {
            var checkAccount = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入用户账号'));
                }
                if (!this.checkAccount(value)) {
                    callback(new Error('字母开头,6-20字节,字母数字下划线'));
                }
                if (value) {
                    //请求后台接口判断用户账号是否已存在
                    this.HTTP.get('/user/existsUserAccount?userAccount=' + value).then(res => {
                        if (res.data.code == 1) {
                            callback();
                        } else {
                            callback(new Error('用户账号已被注册'));
                        }
                    }).catch(err => {
                        this.$message.error(err);
                    })
                }
            };
            var checkPassword = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入用户密码'));
                } else {
                    if (!this.checkPassword(value)) {
                        callback(new Error('6-20字节,只允许字母数字下划线'));
                    }
                    if (this.user.confirmPassword !== '') {
                        this.$refs.ruleForm.validateField('confirmPassword');
                    }
                    callback();
                }
            };

            var checkPassword2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入用户密码'));
                } else if (value !== this.user.userPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };

            var checkPhone = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入手机号码'));
                }
                if (!this.checkMobile(value)) {
                    callback(new Error('手机号码格式错误'));
                }
                if (value) {
                    //请求后台判断手机号是否已注册
                    this.HTTP.get('/user/existsUserPhone?userPhone=' + value).then(res => {
                        if (res.data.code == 1) {
                            callback();
                        } else {
                            callback(new Error('手机号码已被注册'));
                        }
                    }).catch(err => {
                        this.$message.error(err);
                    })
                }
            };

            return {
                user: {
                    userAccount: '',
                    userPassword: '',
                    confirmPassword: '',
                    userPhone: '',
                    type: '0'
                },
                rules: {
                    userAccount: [{validator: checkAccount, required: true, trigger: 'blur'}, {
                        min: 6,
                        max: 20,
                        message: '账号长度在 6 到 20 个字符',
                        trigger: 'blur'
                    }],
                    userPassword: [{validator: checkPassword, required: true, trigger: 'blur'}, {
                        min: 6,
                        max: 20,
                        message: '密码长度在 6 到 20 个字符',
                        trigger: 'blur'
                    }],
                    confirmPassword: [{validator: checkPassword2, required: true, trigger: 'blur'}, {
                        min: 6,
                        max: 20,
                        message: '密码长度在 6 到 20 个字符',
                        trigger: 'blur'
                    }],
                    userPhone: [{validator: checkPhone, required: true, trigger: 'blur'}, {
                        min: 11,
                        max: 11,
                        message: '手机号码长度为 11 个字符',
                        trigger: 'blur'
                    }],
                },
                loading: false,
                timingHanlder: {
                    time: 60,
                    flag: true,
                    text: '发送验证码'
                }
            }
        },
        components: {
            Header,
            Buttom,
        },
        methods: {
            sendMessage: function () {
                this.timingHanlder.flag = false
                this.timing = setInterval(() => {
                    if (this.timingHanlder.time > 0) {
                        this.timingHanlder.time--
                        this.timingHanlder.text = this.timingHanlder.time + '秒'
                    } else {
                        this.timingHanlder.flag = true
                        this.timingHanlder.time = 60
                        this.timingHanlder.text = '发送验证码'
                        clearInterval(this.timing)
                    }
                }, 1000)
            },
            regist: function () {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.HTTP.post("/user/register", this.user).then(res => {
                            if (res.data.success) {
                                this.$message.success("注册成功")
                                this.$router.push("/Login")
                            } else {
                                this.$message.error(res.data.message)
                            }
                            this.loading = false
                        }).catch(err => {
                            this.$message.error("系统开小差了!")
                            this.loading = false
                        })
                    }
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            checkMobile: function (str) {
                var re = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
                if (re.test(str)) {
                    return true;
                } else {
                    return false;
                }
            },
            checkAccount: function (str) {
                var re = /^[a-zA-Z][a-zA-Z0-9_]{5,19}$/;
                if (re.test(str)) {
                    return true;
                } else {
                    return false;
                }
            },
            checkPassword: function (str) {
                var re = /^\w{5,19}$/;
                if (re.test(str)) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .login-container {
        width 100%
        box-sizing border-box
        padding-top 1rem
        background-image url("../assets/images/register-bg.png")
        background-size 100% 100%
        flex 1
    }

    .register-form {
        width 400px
        margin 0 auto
    }

    .container-box {
        margin 0 auto
        max-width 1200px
        background-color #fff
        margin 0 auto
        border 1px solid lightgrey
        border-radius 0.5rem
        padding 1%
    }
    .f-p{
        font-size: 12px;
        color: #cecece;
        text-align: center;
        margin-top: 11em;
    }
</style>
